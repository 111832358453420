import * as React from 'react';

interface HeaderProps {
	type: string;
	logo: string;
	href: string;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
	return (
		<header className="relative z-[100] bg-transparent antialiased">
			<div className="container mx-auto flex items-center justify-between bg-transparent px-6 py-5">
				<a href={props.href} className="h-full pl-1">
					<img className="" src={props.logo} alt="Logo" width={144} />
				</a>
			</div>
		</header>
	);
};

export default Header;
