import * as React from 'react';

declare type Props = {
	bgImage: string;
	text: string;
};

const Banner: React.FC<Props> = ({ bgImage, text }) => {
	return (
		<section
			className="relative"
			style={{
				backgroundImage: `url(${bgImage})`,
				backgroundPosition: '0% 30%',
				position: 'relative',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover'
			}}
		>
			<div className="absolute z-[2] h-full w-full bg-[rgba(15,62,131)] bg-opacity-90"></div>
			<div className="container relative z-[3] mx-auto px-6 py-36 text-white">
				<h1 className="font-hind text-6xl font-bold max-sm:text-5xl">
					Careers
				</h1>
				<p className="my-6 max-w-5xl font-futura text-lg font-bold leading-relaxed tracking-[2px]">
					{text}
				</p>
			</div>
		</section>
	);
};

export default Banner;
