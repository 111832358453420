import * as React from 'react';

const Footer: React.FC = () => {
	return (
		<footer className={'bg-black text-white'}>
			<div className={'container mx-auto py-16 px-6'}>
				<p className="font-varela text-[#777]">
					© {new Date().getFullYear()} Wallis Companies.
				</p>
			</div>
		</footer>
	);
};

export default Footer;
