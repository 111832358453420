import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../../layouts/WallisLayout';
import Banner from '../../components/wallis/Banner/Banner';
import JobSearch from '../../components/wallis/JobSearch/JobSearch';
import wallisLubricantsLogo from '../../images/wallis-lubricants.png';
import lubricantsBanner from '../../images/Lubricants-1.jpg';
import { assignMultipleLocations } from '../../helpers/locations';

const SearchPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	function filterByBrand(edges: any[], brandToFilter: string) {
		return edges.filter(
			(page) => page.node.pageContext.brand === brandToFilter
		);
	}

	data.allSitePage.edges = filterByBrand(
		data.allSitePage.edges,
		'Wallis Co. Lubricants'
	);

	data.allSitePage.edges = assignMultipleLocations(data.allSitePage.edges);

	return (
		<Layout
			href="https://wallislubricants.com/"
			headerType="light"
			logo={wallisLubricantsLogo}
			title="Wallis Lubricants | Employment Opportunities"
			desc="Wallis Lubricants | Employment Opportunities"
		>
			<Banner
				bgImage={lubricantsBanner}
				text="The Wallis Transport Driver is responsible for safe driving during delivery of products, to include accountability for loading and unloading of correct products at the right place and time, operating a safe work environment, reporting maintenance issues of vehicles, and various other duties."
			/>
			<section className={'bg-[#B6E2EC]'}>
				<div className={'container mx-auto py-16 px-6'}>
					<p className="mx-auto max-w-[820px] text-center font-hind text-xl font-medium text-[#1B5AA1]">
					We have been family owned and operated since 1968. We are an equal opportunity employer and operate a drug-free work environment. We are focused on the safety of our team members and our customers — adhering to the required environmental guideline and emphasizing safety in the workplace every day. We have an inhouse shop and prioritize the maintenance and cleanliness of our fleet. We offer competitive pay and benefits with a 3, 4, or 5 day work week option.
					</p>
				</div>
			</section>
			<JobSearch data={data} />
		</Layout>
	);
};
export default SearchPage;
